<template>
  <div class="fuBox box center">
    <div v-if="status.length == 0" class="box center around" style="width:100%">
      <div class="box box_column center">
        <div class="iconfont icon-ic_image_upload" style="font-size:60px"></div>
        <div>上传图片</div>
      </div>
    </div>

    <div v-if="status.length == 1" class="zhengBox box around">
      <div class="bgImg" style="width:100%" id="boxfu">
        <div id="kuai1"></div>
        <div class="box around" v-html="src" style="width:100%"></div>
      </div>
      <!-- <img :src="src" alt="" class="bgImg"> -->
      <div class="title">平面图</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "fuBox",
  data() {
    return {
      status: [],
      name: "名称",
      list: [], //侧视图
      src: "",
      projectId: ""
    };
  },
  //创建
  created() {
    this.projectId = sessionStorage.getItem("projectId");
    this.$bus.$on("delData", this.delData);
    this.$bus.$on("getFuLineData", this.getFuLineData); //接收立面图传过来的值
  },

  //安装
  mounted() {
    this.imgData();
  },

  //方法
  methods: {
    getFuLineData(e) {
      console.log("efushitu", e);

      var dom = document.getElementById("kuai1");
      dom.style.left = e + "%";
      console.log("平面图", dom.style.left);
    },

    delData(e) {
      if (e == 1) {
        this.imgData();
      }
    },

    // -----------------------请求数据------------------------------------
    // 是否有三视图
    imgData() {
      this.$axios
        .get(`${this.baseURL}synthesis/views/list/${this.projectId}`)
        .then((res) => {
          console.log("获取平面图", res.data.data);
          var data = res.data.data;

          data.forEach((item, index) => {
            if (item.typeId == 2) {
              this.status = item.list;
            }
          });
          if (this.status == null) {
            this.status = [];
          } else {
            this.imgSrc(this.status[0].id);
          }
          // this.status = [{}];    //设置有没有截面图
          // console.log('status', this.status.length);
        })
        .catch((err) => {});
    },

    imgSrc(id) {
      this.$axios
        .get(`${this.baseURL}synthesis/img/${id}`)
        .then((res) => {
          // console.log("获取图片流qqqqqqqq", res.data);
          this.src = res.data;
        })
        .catch((err) => {});

      // this.$axios.get(`${this.baseURL}synthesis/img/url/${id}`,).then(res => {
      //       console.log('获取图片流', res.data.data);
      //       this.src = res.data.data;
      //   }).catch(err=>{
      // })
    }
  }
};
</script>
<style scoped lang="scss">
.fuBox {
  width: 100%;
  height: 100%;
  // background: url('../../../assets/images/img/zhengshi_bg.png');
  // border-radius: 28px;
  // background: linear-gradient(to top, #102048, #17356f);
  border: 1px solid #0C3380;
  border-right: 0px;
  background-size: 100% 100%;
  position: relative;
  padding-left: 60px;
  padding-right: 60px;
  .zhengBox {
    width: 100%;
    // height: 100%;
    // overflow: hidden;
    // position: relative;
    // border: 1px solid red;
  }
  .bgImg {
    // width: 100%;
    // height: 100%;
    position: relative;
    // height: 100%;
    // height: 100%;
    // border: 1px solid #ccc;
  }
  // .title {
  //   position: absolute;
  //   bottom: 28px;
  //   left: 50%;
  //   margin-left: -30px;
  // }
  .input {
    position: absolute;
    bottom: 20px;
    left: 50%;
    margin-left: -60px;
    width: 120px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid #8a94a6;
    background: #04153f;
    padding: 0 20px;
  }
}
#kuai1 {
  cursor: pointer;
  position: absolute; /*定位*/
  z-index: 99;
  width: 6px;
  height: 100%;
  // background: blue;  /*设置一下背景*/
  background: url("../../../assets/images/img/set/fushi_xuxian.png");
  background-size: 100% 100%;
}
.title{
      position: absolute;
      top: 32px;
      left: 32px;
      width: 80px;
      text-align: center;
      line-height: 32px;
      height: 32px;
      background: rgba(0, 7, 23, 0.6);
      border-radius: 8px;
      // bottom: 30px;
  }
</style>
