<template>
  <div class="zheng box center" id="">
    <div v-if="status.length == 0" class="box center around" style="width:100%">
      <div class="box box_column center">
        <div class="iconfont icon-ic_image_upload" style="font-size:60px"></div>
        <div>上传图片</div>
      </div>
    </div>
    <div v-if="status.length != 0" class="zhengBox box around">
      <div class="title">立面图</div>
      <!-- <div class="input box center justify">
          <div>=</div>
          <div>{{name}}</div>
          <div>=</div>
        </div> -->
      <!-- <div id=""></div> -->
      <!-- <div class="divBoxFu">
            <div class="pointer"  v-if="freeze == 1" @mousedown="move" id="kuai"></div>
            <div v-else  class="noPointer" id="kuai"></div>
        </div> -->
      <div id="divBox" class="box center around">
        <div class="bgImg">
          <div class="pointer" v-if="freeze == 1" @mousedown="move" id="kuai"></div>
          <div v-else class="noPointer" id="kuai"></div>
          <div v-html="src" class="box center around" style="width:100%"></div>
        </div>
        <!-- <img :src="src" alt="" class="bgImg" /> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "zheng",

  // watch:{
  //   addLine(n, o){
  //     if(n == 1){
  //       console.log(`n`, n)
  //       // this.centerShow();
  //     }
  //   }
  // },

  data() {
    return {
      status: [], //是否有图纸。
      name: "名称",
      list: [], //侧视图
      // src: require('../../../../../几面图/立面图.svg'),
      src: "",
      x: "", //保存截面线
      freeze: "", // 0 冻结   1 解冻
      projectId: ""
    };
  },
  //创建
  created() {
    this.projectId = sessionStorage.getItem("projectId");
    this.$bus.$on("getAddLine", this.getAddLine);

    //  getSave 保存
    //  this.$bus.$on('getSave', this.getSave)

    //  getLine 获取id
    this.$bus.$on("getLine", this.getLine);

    //  删除了图片需要更新
    this.$bus.$on("delData", this.delData);

    // 操作线是否可操作
    this.$bus.$on("freeStatus", this.freeStatus);
  },

  //安装
  mounted() {
    this.imgData(); //是否有三视图
    this.$nextTick(() => {
      this.centerShow();
    });
  },

  //方法
  methods: {
    // 图片删除 更新状态
    delData(e) {
      console.log("删除操作");
      if (e == 1) {
        this.imgData(); //重新读取三视图
      }
    },

    // 操作线是否可操作
    freeStatus(e) {
      this.freeze = e;
    },

    getAddLine(e) {
      console.log("e 父组件的通知", e);
    },
    // getSave(e){
    //   console.log('e 保存的id', e)
    //   this.saveLine(e);
    // },
    // 接收传过来的id值
    getLine(e) {
      console.log("e 获取id", e);
      // 读取是否保存过
      this.getLineData(e);
    },

    // 查询id值得位置
    getLineData(e) {
      this.$axios
        .get(`${this.baseURL}synthesis/select/views/section/${e}`)
        .then((res) => {
          console.log("获取线的位置", res.data.data.x);
          var num = res.data.data.x / 100;

          var x = document.getElementById("divBox").offsetWidth * num;
          console.log(`x轴百分比`, x);

          var el = document.getElementById("kuai");
          el.style.left = x + "px"; //x轴移动

          this.$bus.$emit("getFuLineData", num * 100); //发送给平面图进行保存
        })
        .catch((err) => {});
    },

    // 一进入居中屏幕
    centerShow() {
      var divX = document.getElementById("divBox").offsetWidth / 2;
      var el = document.getElementById("kuai");
      el.style.left = divX - 5 + "px"; //x轴移动
      var x = divX - 5; //页面显示的值

      // var divY = document.getElementById('divBox').offsetHeight/2;
      // el.style.top = (divY-45) +'px';     //y轴移动
      // var y = divY-45;    //页面显示的值

      this.positionX = x;
      // this.positionY = y;

      var x1 = x / document.getElementById("divBox").offsetWidth;
      console.log("x轴百分比", x1.toFixed(3));

      // var y1 = y/document.getElementById('divBox').offsetHeight;
      // console.log('y轴百分比',  y1.toFixed(3));
    },

    move(e) {
      // if(this.state == 2){
      //   return
      // }

      let odiv = e.target; // 获取目标元素

      //计算出鼠标相对点击元素的位置,e.clientX获取的是鼠标的位置，OffsetLeft是元素相对于外层元素的位置
      let x = e.clientX - odiv.offsetLeft;
      // let y = e.clientY - odiv.offsetTop;
      console.log(odiv.offsetLeft, odiv.offsetTop);
      document.onmousemove = (e) => {
        // 获取拖拽元素的位置
        let left = e.clientX - x;
        // let top = e.clientY - y;
        //console.log(document.documentElement.clientHeight,odiv.offsetHeight)
        // 把拖拽元素 放到 当前的位置
        if (left <= 0) {
          left = 0;
        } else if (left >= document.documentElement.clientWidth - odiv.offsetWidth) {
          //document.documentElement.clientWidth 屏幕的可视宽度
          left = document.documentElement.clientWidth - odiv.offsetWidth;
        }

        // if (top <= 0) {
        // top = 0;
        // } else if (top >= document.documentElement.clientHeight - odiv.offsetHeight){
        // // document.documentElement.clientHeight 屏幕的可视高度
        // top = document.documentElement.clientHeight - odiv.offsetHeight
        // }

        // let maxX = parseInt(document.getElementById('zujian').offsetWidth - parseInt(window.getComputedStyle(e).width));
        // let maxY = parseInt(document.getElementById('zujian').offsetHeight - parseInt(window.getComputedStyle(e).height));
        console.log("zujian", document.getElementById("divBox"));

        let maxX = document.getElementById("divBox").offsetWidth - 5;
        // let maxY = document.getElementById('divBox').offsetHeight-80;

        if (left > maxX) {
          left = maxX;
        }

        // if (top > maxY) {
        //   top = maxY
        // }

        this.positionX = left;
        // this.positionY = top;

        this.percentage(this.positionX, this.positionY); //获取百分比数值
        odiv.style.left = left + "px";

        // this.x = left;

        // odiv.style.top = top + "px"
      };
      // 为了防止 火狐浏览器 拖拽阴影问题
      document.onmouseup = (e) => {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    },

    // 计算百分比
    percentage(x, y) {
      var x1 = x / document.getElementById("divBox").offsetWidth;
      // console.log('x轴百分比',  x1.toFixed(3));

      // var y1 = y/document.getElementById('divBox').offsetHeight;
      // console.log('y轴百分比',  y1.toFixed(3));
      console.log("x", x1);

      // return;

      var num = x1 * 100;
      console.log("num", num);

      // return

      // var x = document.getElementById('divBox').offsetWidth*num;
      // console.log(`x轴百分比`, x)

      this.$bus.$emit("getLineData", num); //发送给主场景进行保存
      this.$bus.$emit("getFuLineData", num); //发送给平面图进行保存
    },

    // -----------------------请求数据------------------------------------
    // 是否有三视图
    imgData() {
      this.$axios
        .get(`${this.baseURL}synthesis/views/list/${this.projectId}`)
        .then((res) => {
          console.log("获取立面图", res.data.data);
          var data = res.data.data;
          data.forEach((item, index) => {
            if (item.typeId == 1) {
              this.status = item.list;
            }
          });
          if (this.status == null) {
            this.status = [];
          } else {
            this.imgSrc(this.status[0].id);
          }
          // this.status = [{}];    //设置有没有截面图
          console.log("status", this.status.length);
        })
        .catch((err) => {});
    },

    imgSrc(id) {
      this.$axios
        .get(`${this.baseURL}synthesis/img/${id}`)
        .then((res) => {
          // console.log("获取图片流", res.data);
          this.src = res.data;
        })
        .catch((err) => {});

      // this.$axios.get(`${this.baseURL}synthesis/img/url/${id}`,).then(res => {
      //       console.log('获取图片流', res.data.data);
      //       this.src = res.data.data;
      //   }).catch(err=>{
      // })
    }

    // 保存 截面
    // saveLine(arr){
    //   // return;
    //   var arr = {
    //       "sectionId":  arr.sectionId,
    //       "viewsId": arr.viewsId,
    //       "x": this.x
    //   }
    //   console.log('arr', arr);

    //   // return
    //   var id = "10008"
    //     this.$axios.post(`${this.baseURL}synthesis/save/views/section`, arr).then(res => {
    //          console.log('保存截面', res);

    //     }).catch(err=>{
    //     })
    // },
  }
};
</script>
<style scoped lang="scss">
.zheng {
  width: 100%;
  height: 100%;
  // background: url('../../../assets/images/img/zhengshi_bg.png');
  // border-radius: 28px;
  // background: linear-gradient(to top, #102048, #17356f);
  border: 1px solid #0C3380;
  border-right: 0px;
  border-bottom: 0px;
  background-size: 100% 100%;
  position: relative;
  padding-left: 60px;
  padding-right: 60px;
  .zhengBox {
    width: 100%;
    // height: 100%;
  }

  // .title {
  //   position: absolute;
  //   bottom: 80px;
  //   left: 50%;
  //   margin-left: -30px;
  //   user-select: none;
  // }
  .input {
    position: absolute;
    bottom: 20px;
    left: 50%;
    margin-left: -60px;
    width: 120px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid #8a94a6;
    background: #04153f;
    padding: 0 20px;
  }
}
// .divBoxFu{
//   width: 50%;
//   height: 100%;
//     user-select: none;
//   overflow: hidden;
//   position: relative;
//   top: 0;
//   border: 1px solid red;
// }

#divBox {
  width: 100%;   // 50%
  height: 100%;
  user-select: none;
  overflow: hidden;
  position: relative;
  z-index: 2;
  .bgImg {
    width: 100%;
    // height: 100%;
    // pointer-events: none;
    z-index: 2;
    // border: 1px solid #ccc;
  }
}

#kuai {
  position: absolute; /*定位*/
  z-index: 99;
  width: 8px;
  height: 100%;
  // background: blue;  /*设置一下背景*/
  background: url("../../../assets/images/img/set/zhengshi_xuxian 2.png");
  background-size: 100% 100%;
}
.title{
      position: absolute;
      top: 32px;
      left: 32px;
      width: 80px;
      text-align: center;
      line-height: 32px;
      height: 32px;
      background: rgba(0, 7, 23, 0.6);
      border-radius: 8px;
      // bottom: 30px;
  }
</style>
