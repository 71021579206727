<template>
  <!-- 侧视图 -->
  <div class="zheng box">
    <div v-if="list.length == 0" class="box center around" style="width: 100%">
      <div class="box box_column center">
        <div class="iconfont icon-ic_image_upload" style="font-size: 60px"></div>
        <div>上传图片</div>
      </div>
    </div>

    <!-- <div class="img">放大</div> -->
    <div v-if="list.length > 0" class="divBox1Fu box center" style="width: 100%; height: 100%">
      <!-- <div class="title1">{{name}}</div> -->
      <div class="title">剖面图</div>
      <div class="percent">
        <span>比例</span>
        <input type="number" step="10" v-model="inputPercentValue" @change="handleStepChange" />
        <span class="fuhao">%</span>
      </div>
      <el-button @click="savePercent" class="mlbtn" :class="!myOption ? 'disable_icon' : ''">保存</el-button>
      <div class="move-btn-wrapper">
        <el-button @click="handleMoveTop" :class="!myOption ? 'disable_icon' : ''">上移</el-button>
        <el-button @click="handleMoveBottom" :class="!myOption ? 'disable_icon' : ''">下移</el-button>
        <el-button @click="handleMoveLeft" :class="!myOption ? 'disable_icon' : ''">左移</el-button>
        <el-button @click="handleMoveRight" :class="!myOption ? 'disable_icon' : ''">右移</el-button>
      </div>
      <div class="input box center justify">
        <div class="pointer" @click="getLeft">
          <span class="iconfont icon-arrow_left"></span>
        </div>
        <div style="width: 50px" class="elli" :title="name">{{ name }}</div>
        <div class="pointer" @click="getRight">
          <span class="iconfont icon-arrow_right"></span>
        </div>
      </div>
      <div id="divBox1" class="box" :style="{ top: divTop + '%', left: divLeft + '%' }">
        <!-- <div @mousedown="move" id="kuai1"></div> -->
        <div class="bgImg box" v-html="src"></div>
      </div>
    </div>
  </div>
</template>

<script>
import qs from "qs";
export default {
  name: "zheng",
  data() {
    return {
      name: "名称",
      list: [], //侧视图
      src: "",
      arrindex: 0,
      arr: [], //侧视图列表
      projectId: "",
      inputPercentValue: 100, // 默认展示比例100%，原始大小
      currentImg: "", // 当前展示的图片
      divTop: 0,
      divLeft: 0
    };
  },
  //创建
  created() {
    this.projectId = sessionStorage.getItem("projectId");
    this.$bus.$on("getPointer", this.getPointer);
    this.$bus.$on("delData", this.delData);
    this.$bus.$on("getLineIndex", this.getLineIndex); //侧视图的index
  },

  //安装
  mounted() {
    this.getCeData(); //获取三视图id
    setTimeout(() => {
      this.name = this.arr[0].name; //进入后直接获取 截面的名字
      this.$bus.$emit("getLine", this.arr[0].id); //进入后获取 截面的 id，发送到立面图查询位置，立面图会传给平面图
      this.$bus.$emit("getPointIndexActive", this.arr[0]); // 剖面图翻页，测点列表相应选中
      if (!this.arr[0].axleValueX) {
        this.divLeft = 0;
      } else {
        this.divLeft = this.arr[0].axleValueX;
      }

      if (!this.arr[0].axleValueY) {
        this.divTop = 0;
      } else {
        this.divTop = this.arr[0].axleValueY;
      }

      if (!this.arr[0].proportion) {
        this.inputPercentValue = 100;
      } else {
        this.inputPercentValue = this.arr[0].proportion;
      }
      this.scaleFun();
    }, 200);
  },
  //方法
  methods: {
    // 函数 放大/缩小
    scaleFun() {
      if (this.inputPercentValue > 10) {
        let ele = document.getElementById("divBox1");
        let num = this.inputPercentValue + "%";
        console.log("缩放比例", num);
        ele.style.transform = `scale(${num})`;
      }
    },
    // 调整百分比例 放大/缩小
    handleStepChange() {
      // 最小比例为0，剖面图完全隐藏
      if (this.inputPercentValue < 0) {
        this.inputPercentValue = 0;
        return;
      }
      // 调用缩放函数
      this.scaleFun();
    },
    // 保存放大/缩小比例
    savePercent() {
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }
      console.log("当前展示图片的id", this.currentImg.id);
      let data = {
        id: this.currentImg.id,
        proportion: this.inputPercentValue,
        axleValueX: this.divLeft,
        axleValueY: this.divTop
      };
      this.$axios.post(`${this.baseURL}synthesis/save/proportion`, data).then((res) => {
        console.log("图片缩放比例res", res);
        if (res.status == "200") {
          this.$message.success("保存成功");
          this.getCeData();
        } else {
          this.$message.error(res.errMsg);
        }
      });
    },

    // 左右
    getLeft() {
      if (this.arrindex == 0) {
        return;
      }
      this.arrindex = this.arrindex - 1;
      var item = this.arr[this.arrindex];
      this.currentImg = item;

      console.log("item kkkkkkkkkkkkkkkkkkkkkkk", item);

      if (item.axleValueX) {
        this.divLeft = item.axleValueX;
      } else {
        this.divLeft = 0;
      }

      if (item.axleValueY) {
        this.divTop = item.axleValueY;
      } else {
        this.divTop = 0;
      }

      if (!item.proportion) {
        item.proportion = 100;
      }
      this.inputPercentValue = item.proportion;
      this.scaleFun();

      this.name = item.name;
      this.imgSrc(item.id);
      this.$bus.$emit("getLine", item.id); //发送给立面图，  立面图会关联到平面图
      this.$bus.$emit("getPointIndexActive", item); // 剖面图翻页，测点列表相应选中
    },
    // 左右
    getRight() {
      if (this.arrindex == this.arr.length - 1) {
        return;
      }
      this.arrindex = this.arrindex + 1;
      var item = this.arr[this.arrindex];
      this.currentImg = item;
      if (!item.proportion) {
        item.proportion = 100;
      }
      this.inputPercentValue = item.proportion;
      this.scaleFun();
      if (item.axleValueX) {
        this.divLeft = item.axleValueX;
      } else {
        this.divLeft = 0;
      }

      if (item.axleValueY) {
        this.divTop = item.axleValueY;
      } else {
        this.divTop = 0;
      }
      console.log("item", item);
      this.name = item.name;
      this.imgSrc(item.id);
      this.$bus.$emit("getLine", item.id); //发送给立面图，  立面图会关联到平面图
      this.$bus.$emit("getPointIndexActive", item); // 剖面图翻页，测点列表相应选中
    },

    // 关联显示侧视图数字
    getLineIndex(e) {
      this.arrindex = e;
      var item = this.arr[this.arrindex];
      this.name = item.name;
      this.imgSrc(item.id);
      // 测点列表点击时，显示缩放比例
      if (item.proportion) {
        this.inputPercentValue = item.proportion;
      } else {
        this.inputPercentValue = 100;
      }
      this.scaleFun();
    },

    getPointer(e) {
      console.log("e传过来的测点", e);
    },

    delData(e) {
      if (e == 1) {
        this.getCeData();
      }
    },

    // 一进入居中屏幕
    centerShow() {
      var divX = document.getElementById("divBox1").offsetWidth / 2;
      var el = document.getElementById("kuai1");
      el.style.left = divX - 30 + "px"; //x轴移动
      var x = divX - 30; //页面显示的值

      var divY = document.getElementById("divBox1").offsetHeight / 2;
      el.style.top = divY - 30 + "px"; //y轴移动
      var y = divY - 30; //页面显示的值

      this.positionX = x;
      this.positionY = y;

      var x1 = x / document.getElementById("divBox1").offsetWidth;
      console.log("x轴百分比", x1.toFixed(3));

      var y1 = y / document.getElementById("divBox1").offsetHeight;
      console.log("y轴百分比", y1.toFixed(3));
    },

    // 计算百分比
    percentage(x, y) {
      var x1 = x / document.getElementById("divBox1").offsetWidth;
      console.log("x轴百分比", x1.toFixed(3));

      var y1 = y / document.getElementById("divBox1").offsetHeight;
      console.log("y轴百分比", y1.toFixed(3));
    },

    move(e) {
      let odiv = e.target; // 获取目标元素

      //计算出鼠标相对点击元素的位置,e.clientX获取的是鼠标的位置，OffsetLeft是元素相对于外层元素的位置
      let x = e.clientX - odiv.offsetLeft;
      let y = e.clientY - odiv.offsetTop;
      console.log(odiv.offsetLeft, odiv.offsetTop);
      document.onmousemove = (e) => {
        // 获取拖拽元素的位置
        let left = e.clientX - x;
        let top = e.clientY - y;
        //console.log(document.documentElement.clientHeight,odiv.offsetHeight)
        // 把拖拽元素 放到 当前的位置
        if (left <= 0) {
          left = 0;
        } else if (left >= document.documentElement.clientWidth - odiv.offsetWidth) {
          //document.documentElement.clientWidth 屏幕的可视宽度
          left = document.documentElement.clientWidth - odiv.offsetWidth;
        }

        if (top <= 0) {
          top = 0;
        } else if (top >= document.documentElement.clientHeight - odiv.offsetHeight) {
          // document.documentElement.clientHeight 屏幕的可视高度
          top = document.documentElement.clientHeight - odiv.offsetHeight;
        }

        // let maxX = parseInt(document.getElementById('zujian').offsetWidth - parseInt(window.getComputedStyle(e).width));
        // let maxY = parseInt(document.getElementById('zujian').offsetHeight - parseInt(window.getComputedStyle(e).height));
        console.log("zujian", document.getElementById("divBox1"));

        let maxX = document.getElementById("divBox1").offsetWidth - 30;
        let maxY = document.getElementById("divBox1").offsetHeight - 30;

        if (left > maxX) {
          left = maxX;
        }

        if (top > maxY) {
          top = maxY;
        }

        this.positionX = left;
        this.positionY = top;

        this.percentage(this.positionX, this.positionY); //获取百分比数值
        odiv.style.left = left + "px";
        odiv.style.top = top + "px";
      };
      // 为了防止 火狐浏览器 拖拽阴影问题
      document.onmouseup = (e) => {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    },

    // 向上平移
    handleMoveTop() {
      console.log("caozuo", this.myOption);
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }
      let ele = document.getElementById("divBox1");
      this.divTop -= 5;
    },
    // 向下平移
    handleMoveBottom() {
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }
      let ele = document.getElementById("divBox1");
      this.divTop += 5;
    },
    // 向左平移
    handleMoveLeft() {
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }
      let ele = document.getElementById("divBox1");
      this.divLeft -= 5;
    },
    // 向右平移
    handleMoveRight() {
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }
      let ele = document.getElementById("divBox1");
      this.divLeft += 5;
    },

    // ---------------------请求数据-------------------------------------------------------
    getCeData() {
      this.$axios
        .get(`${this.baseURL}synthesis/views/list/${this.projectId}`)
        .then((res) => {
          console.log("侧视图", res);
          var list = res.data.data;
          list.forEach((item, index) => {
            if (item.typeId == 3) {
              this.list = item.list;
            }
          });

          if (this.list == null) {
            this.list = [];
          } else {
            console.log("当前展示的图片pppppppppppppppppppppppppp", this.currentImg);
            if (!this.currentImg) {
              this.imgSrc(this.list[0].id);
              this.currentImg = this.list[0];
            }
          }
          this.arr = this.list;
          // console.log('list', this.list);
        })
        .catch((err) => {});
    },

    imgSrc(id) {
      this.$axios
        .get(`${this.baseURL}synthesis/img/${id}`)
        .then((res) => {
          console.log("获取图片流", res);
          this.src = res.data;
        })
        .catch((err) => {});
    }
  }
};
</script>
<style scoped lang="scss">
.zheng {
  width: 100%;
  height: 100%;
  // background: url('../../../assets/images/img/poumian_bg.png');
  // border-radius: 28px;
  // background: linear-gradient(to top, #102048, #17356f);
  border: 1px solid #0C3380;
  background-size: 100% 100%;
  position: relative;
  padding-left: 60px;
  padding-right: 60px;
  overflow: hidden;
  .img {
    position: absolute;
    top: 24px;
    right: 24px;
  }
  .bgImg {
    width: 100%;
    height: 100%;
    // border: 1px solid #ccc;
  }
  //  .title{
  //   position: absolute;
  //   text-align: center;
  //   bottom: 118px;
  //   width: 100%;
  // }
  .title1 {
    position: absolute;
    width: 100%;
    bottom: 148px;
    text-align: center;
  }
  .input {
    position: absolute;
    bottom: 32px;
    width: 140px;
    text-align: center;
    left: 50%;
    margin-left: -70px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid #8a94a6;
    background: rgba(0, 7, 23, 0.6);
    padding: 0 20px;
    z-index: 2;
  }
}
.divBox1Fu {
  position: relative;
}

#divBox1 {
  width: 100%;
  // height: 100%;
  position: absolute;
}

#kuai1 {
  position: absolute;
  width: 60px;
  height: 60px;
  background: chartreuse;
}
.move-btn-wrapper {
  position: absolute;
  top: 75px;
  z-index: 9;
  .el-button {
    padding: 6px 20px;
  }
  .el-button:hover {
    cursor: pointer;
  }
}
.percent {
  z-index: 9;
  position: absolute;
  top: 32px;
  left: 95px;
  line-height: 32px;
  input {
    width: 100px;
    padding: 0 10px;
    line-height: 32px;
    border-radius: 16px;
    margin: 0 15px;
    background: none;
    color: #fff;
    border: 1px solid #2667db;
  }
  .fuhao {
    position: absolute;
    right: 50px;
    z-index: 9;
  }
}

.mlbtn {
  position: absolute;
  top: 32px;
  left: 260px;
  z-index: 9;
}
.mlbtn:hover {
  cursor: pointer;
}

.title {
  position: absolute;
  top: 32px;
  left: 0px;
  width: 80px;
  text-align: center;
  line-height: 32px;
  height: 32px;
  background: rgba(0, 7, 23, 0.6);
  border-radius: 8px;
  // bottom: 30px;
}
</style>
